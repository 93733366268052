import React from 'react';
import {generateAvatarFromHash} from "../utils/helpers";
import Const from "../core/Const";

const Avatar = ({user, type}) => {
    const author = user ? user : {
        _id: "EMPTYEMPTY",
        last_name: "A",
        first_name: "A",
        avatar: ""
    }

    const {color} = generateAvatarFromHash(author._id);
    let firstChar = "";

    if (author.last_name[0]) {
        firstChar = author.last_name[0].toUpperCase();
    }


    let avatar = author.avatar;
    let url = Const.URL + author.avatar;

    if (type === "lg") {
        return (
            <div className="avatar avatar-lg bg-info text-light" style={{background: `linear-gradient(135deg, ${color} 0%, ${color} 100%)`}}>
                {avatar ? <img src={url} alt=""/> : <span>{firstChar}</span>}
            </div>
        )
    }

    if (type === "sm") {
        return (
            <div className="avatar avatar-sm bg-info text-light" style={{background: `linear-gradient(135deg, ${color} 0%, ${color} 100%)`}}>
                {avatar ? <img src={url} alt=""/> : <span>{firstChar}</span>}
            </div>
        );
    }
    if (type === "xl") {
        return (
            <div className="avatar avatar-xl bg-info text-light" style={{background: `linear-gradient(135deg, ${color} 0%, ${color} 100%)`}}>
                {avatar ? <img src={url} alt=""/> : <span>{firstChar}</span>}
            </div>
        );
    }

    return (
        <div className="avatar bg-info text-light" style={{background: `linear-gradient(135deg, ${color} 0%, ${color} 100%)`}}>
            {avatar ? <img src={url} alt=""/> : <span>{firstChar}</span>}
        </div>
    );
};

export default Avatar;
