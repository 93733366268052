const initialState = {
    items: [],
    categories: [],
    currentDialogId: window.location.pathname.split('dialog/')[1],
    isLoading: false,
    currentPage: 1,
    isArchive: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case "CATEGORIES:SET_ITEMS":
            return {
                ...state,
                categories: payload
            };
        case "DIALOGS:CURRENT_PAGE":
            return {
                ...state,
                currentPage: payload
            };
        case "DIALOGS:IS_ARCHIVE":
            return {
                ...state,
                isArchive: payload
            };
        case 'DIALOGS:SET_ITEMS':
            let currentPage = state.currentPage;
            return {
                ...state,
                items: currentPage === 1 ? payload : state.items.concat(payload)
            };
        case 'DIALOGS:LAST_MESSAGE_READED_STATUS':
            return {
                ...state,
                items: state.items.map(dialog => {
                    if (dialog._id === payload.dialogId) {
                        dialog.lastMessage.readed = true;
                    }
                    return dialog;
                }),
            };
        case 'DIALOGS:SET_CURRENT_DIALOG_ID':
            return {
                ...state,
                currentDialogId: payload,
            };
        default:
            return state;
    }
};
