import React, {useEffect} from 'react';
import Navigation from "../components/Navigation";
import {connect} from "react-redux";
import {dialogsActions} from "../redux/actions";
import {useParams, withRouter} from "react-router";
import Dialogs from "../components/Dialogs";
import Messages from "../components/Messages";

const Dialog = ({user, setCurrentDialogId, fetchCategories}) => {

    const {id} = useParams();

    useEffect(() => {
        fetchCategories();

        setCurrentDialogId(id);
    }, [id]);

    if (user == null) {
        return "";
    }

    return (
        <div className="profile-tab-open">
            <div className="main-layout">
                <Navigation/>
                <Dialogs/>
                <Messages user={user}/>
            </div>
        </div>
    );
};

export default withRouter(
    connect(
        ({user}) => ({user: user.data}),
        dialogsActions,
    )(Dialog),
);

