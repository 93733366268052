import React from "react";
import {Form, Modal} from "react-bootstrap";
import {Formik} from "formik";
import Yup from "../utils/YupLocale";
import {messagesActions} from "../redux/actions";
import {connect} from "react-redux";

const UpdateModal = ({modelShow, modelClose, messageID, messageText, fetchUpdateMessage, updateMessage}) => {

    const close = () => "";

    const schema = Yup.object({
        text: Yup.string().required().label("Матн"),
    });

    const handleSubmit = (values, {resetForm}) => {
        fetchUpdateMessage(values.text, messageID).then(item => {
            if (item) {
                document.getElementById("message-" + item._id).getElementsByClassName("message-text")[0].innerHTML = item.text
            }
        });
        modelClose();
        resetForm();
    };

    return (
        <>
            <Modal
                show={modelShow} onHide={close}
            >
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                        initialValues={{
                            text: messageText,
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              errors,
                              values,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="validationFormik-close">
                                    <Form.Control
                                        as={"textarea"}
                                        className="form-control"
                                        name={"text"}
                                        onChange={handleChange}
                                        value={values.text}
                                        rows={10}
                                    />
                                    {errors.text && errors.text}
                                </Form.Group>
                                <button onSubmit={handleSubmit} type="submit" className="btn btn-success">
                                    Ўзгартириш
                                </button>
                                <button className="btn btn-secondary ml-4" onClick={modelClose} type="button">
                                    Ёпиш
                                </button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default connect(
    () => ({}),
    messagesActions
)(UpdateModal);
