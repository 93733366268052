import React from 'react';
import classNames from 'classnames';
import Avatar from "./Avatar";
import {format, isToday, isValid} from "date-fns";
import ModalImage from "react-modal-image";
import Const from "../core/Const";
import Linkify from "react-linkify";


const MessageItem = ({currentDialog, item, attachments, createDialog, updateMessage, deleteMessage}) => {

    let isSelf = "";
    let isArchiveMessage = "";

    if (currentDialog.author) {
        if ((item.user ? item.user._id : "") !== currentDialog.author._id) {
            isSelf = "self";
        }
    }

    if (item.dialog._id !== currentDialog._id) {
        isArchiveMessage = "message-old";
    }

    const getMessageTime = date => {
        date = new Date(date);
        if (!isValid(date)) {
            return ""
        }
        if (isToday(date)) {
            return format(date, 'HH:mm');
        } else {
            return format(date, 'd.MM.Y HH:mm');
        }
    };

    const bytesToSize = bytes => {
        let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const renderAttachment = item => {

        if (!item) {
            return "";
        }

        let extension = item.filename.split('.').pop().toLowerCase();
        let image = false;

        if (extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "bmp") {
            image = true;
        }

        let url = Const.URL + item.url;

        return (<div
            key={item._id}
        >
            <div className="document">
                {image ? <div className="message-img">
                        <ModalImage
                            small={url}
                            large={url}
                            alt={item.filename}
                        />
                    </div> : // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={url} className="btn btn-primary btn-icon rounded-circle text-light mr-2" target={"_blank"}>
                        <svg className="hw-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"/>
                        </svg>
                    </a>}

                <div className="document-body">
                    <h6>
                        <div className="text-reset" title={item.filename}>{item.filename}</div>
                    </h6>

                    <ul className="list-inline small mb-0">
                        <li className="list-inline-item">
                            <span className="text-muted">{bytesToSize(item.size)}</span>
                        </li>
                        <li className="list-inline-item">
                            <span className="text-muted text-uppercase">{extension}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>);
    };

    if (item.text.length === 0 && item.attachments.length === 0) {
        return "";
    }

    return (<>
        <div className={classNames("message", isSelf, isArchiveMessage)} id={`message-${item._id}`}>
            <div className="message-wrapper">
                <div className="message-content">

                    {attachments && (<div className="message__attachments">
                        {attachments.map(item => renderAttachment(item))}
                    </div>)}

                    <div className="message-text">
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>)}
                        >
                            {item.text}
                        </Linkify>
                    </div>
                </div>
            </div>
            <div className="message-options">
                <Avatar user={item.user} type="sm"/>
                <span className="message-date">{getMessageTime(item.createdAt)}</span>
                {isSelf !== "self" ? <>
                    <div className="dropdown-plus" onClick={() => createDialog(item)}>
                        Янги диалог
                    </div>
                </> : <>
                    <div className="dropdown-plus remove" onClick={() => deleteMessage(item)}>
                        Ўчириш
                    </div>
                    <div className="dropdown-plus" onClick={() => updateMessage(item)}>
                        Ўзгартириш
                    </div>
                </>}
            </div>
        </div>
    </>);
};

export default MessageItem;
