import React, {useState} from 'react';
import {connect} from 'react-redux';
import {filesApi} from '../utils/api';
import {attachmentsActions, messagesActions} from '../redux/actions';


const ChatInput = (props) => {

    const [userInput, setUserInput] = useState();
    let inputReference = React.createRef();

    const {
        dialogs: {currentDialogId},
        fetchSendMessage,
        attachments,
        setAttachments,
    } = props;

    if (!currentDialogId) {
        return null;
    }

    const fileUploadAction = () => {
        inputReference.current.click()
    }

    const fileUploadInputChange = async files => {
        let file = files.target.files[0];
        let uploaded = [];

        const uid = Math.round(Math.random() * 1000);
        uploaded = [
            ...uploaded,
            {
                uid,
                name: file.name,
                status: 'uploading',
            },
        ];
        setAttachments(uploaded);
        // eslint-disable-next-line no-loop-func
        await filesApi.upload(file).then(({data}) => {
            uploaded = uploaded.map(item => {
                if (item.uid === uid) {
                    return {
                        status: 'done',
                        uid: data.file._id,
                        name: data.file.filename,
                        url: data.file.url,
                    };
                }
                return item;
            });
        }).catch((err) => {
            console.log(err);
        });
        setAttachments(uploaded);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            return sendMessage(event);
        }
    }

    const sendMessage = (event) => {
        event.preventDefault();
        const text = userInput.textContent;
        if ((text && text.length > 0) || attachments.length > 0) {
            fetchSendMessage({
                text: text,
                dialogId: currentDialogId,
                attachments: attachments.map(file => file.uid),
            });
            setAttachments([]);
            userInput.innerHTML = "";
        }
    };

    return (
        <div className="form-row">
            <div className="col">
                <div className="input-group user-group">
                    <div className="input-group-prepend mr-sm-2 mr-1">
                        <button className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted text-muted" onClick={() => fileUploadAction()}>
                            <svg className="hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        </button>
                        <input type="file" ref={inputReference} hidden onChange={(e) => fileUploadInputChange(e)}/>
                    </div>
                    <div
                        ref={setUserInput}
                        role="button"
                        tabIndex="0"
                        onKeyDown={handleKeyDown}
                        contentEditable="true"
                        className="form-control cfc transparent-bg user-input border-0 no-resize hide-scrollbar"
                        placeholder="Хабарни ёзинг..."
                    />
                </div>
            </div>
            <div className="col-auto">
                <div className="btn btn-primary btn-icon rounded-circle text-light mb-1" role="button" onClick={sendMessage}>
                    <svg className="hw-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default connect(
    ({dialogs, attachments, user}) => ({
        dialogs,
        attachments: attachments.items,
        user: user.data,
    }),
    {...messagesActions, ...attachmentsActions},
)(ChatInput);
