import * as Yup from "yup";
import {setLocale} from "yup";

setLocale({
    mixed: {
        default: 'Não é válido',
        // eslint-disable-next-line no-template-curly-in-string
        required: '«${path}» тўлдириш шарт',
    },
    number: {
        // eslint-disable-next-line no-template-curly-in-string
        cast: '«${message}» тўлдириш шарт',
        // eslint-disable-next-line no-template-curly-in-string
        min: '«${path}» қиймати «${min}»дан катта бўлиши шарт',
        // eslint-disable-next-line no-template-curly-in-string
        max: '«${path}» қиймати «${max}»дан кичик бўлиши шарт',
    },
});


export default Yup;
