import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {messagesActions} from '../redux/actions';
import socket from '../core/socket';
import BaseMessages from "./BaseMessages";
import {useParams} from "react-router";
import Loading from "./Loading";

const Messages = ({currentDialog, fetchMessages, addMessage, items, user, isLoading}) => {

    const {id} = useParams();

    const messagesRef = useRef(null);

    const onNewMessage = data => {
        addMessage(data);
    };

    useEffect(() => {
        if (id) {
            fetchMessages(id);
        }

        socket.on('SERVER:NEW_MESSAGE', onNewMessage);

        return () => {
            socket.removeListener('SERVER:NEW_MESSAGE', onNewMessage);
        }
    }, [id]);


    useEffect(() => {
        if (messagesRef.current != null) {
            messagesRef.current.scrollTo(0, 999999);
        }
    }, [items]);

    if (user == null) {
        return "";
    }

    if(isLoading){
        return <Loading />
    }

    if (!currentDialog) {
        return (<>
            <main className="main">
                <div className="chats">
                    <div className="d-flex flex-column justify-content-center text-center h-100 w-100">
                        <div className="container">
                            Диалог топилмади...
                        </div>
                    </div>
                </div>
            </main>
        </>);
    }

    return (
        <>
            <BaseMessages
                user={user}
                blockRef={messagesRef}
                items={items}
                isLoading={isLoading && !user}
                currentDialog={currentDialog}
                partner={
                    currentDialog.author
                }
            />
        </>
    )
};

export default connect(
    ({dialogs, messages, user, attachments}) => ({
        items: messages.items.messages,
        currentDialog: messages.items.dialog,
        isLoading: messages.isLoading,
        attachments: attachments.items,
        user: user.data,
    }),
    messagesActions,
)(Messages);
