import React from "react";
import {connect} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";

import {Auth, Home} from "./pages";
import Profile from "./pages/Profile";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import Dialog from "./pages/Dialog";


const App = props => {
    const {isAuth} = props;

    return (
        <div className="wrapper">
            <NotificationContainer />
            <Switch>
                <Route
                    exact
                    path={["/sign-in"]}
                    component={Auth}
                />
                <Route
                    exact={true}
                    path="/"
                    render={() => (isAuth ? <Home/> : <Redirect to="/sign-in"/>)}
                />
                <Route
                    exact={true}
                    path="/dialog/:id"
                    render={() => (isAuth ? <Dialog/> : <Redirect to="/sign-in"/>)}
                />
                <Route
                    path="/profile"
                    render={() => (isAuth ? <Profile/> : <Redirect to="/sign-in"/>)}
                />
            </Switch>
        </div>
    );
};

export default connect(({user}) => ({isAuth: user.isAuth}))(App);
