import React from 'react';
import Navigation from "../components/Navigation";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Dialogs from "../components/Dialogs";
import {userActions} from "../redux/actions";
import Welcome from "../components/Welcome";

const Home = ({user}) => {

    return (
        <>
            <div className="main-layout">
                <Navigation/>
                <Dialogs/>
                <Welcome user={user}/>
            </div>
        </>
    );
};

export default withRouter(
    connect(
        ({user}) => ({user: user.data}),
        userActions
    )(Home),
);
