import React from 'react';

const Loading = () => {
    return (
        <main className="main">
            <div className="chats">
                <div className="d-flex flex-column justify-content-center text-center h-100 w-100">
                    <div className="container">
                        Юкланмоқда...
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Loading;
