import React, {useState} from 'react';
import MessageItem from "./MessageItem";
import Avatar from "./Avatar";
import ChatInput from "./ChatInput";
import {Link} from "react-router-dom";
import {dialogsActions} from "../redux/actions";
import {connect} from "react-redux";
import UpdateModal from "./UpdateModal";
import ChildModal from "./ChildModal";
import {messagesApi} from "../utils/api";


const BaseMessages = ({partner, items, user, blockRef, currentDialog, categories}) => {

    const [transferClass, setTransferClass] = useState("d-none");
    const [activeCategoryID, setCategoryID] = useState("");

    const [show, setShow] = useState(false);
    const [showChild, setShowChild] = useState(false);
    const [messageID, setMessageID] = useState(0);
    const [messageText, setMessageText] = useState("");

    const handleClose = () => setShow(false);
    const handleChildClose = () => setShowChild(false);

    const handleShow = (id, text) => {
        setMessageID(id);
        setMessageText(text);
        setShow(true);
    };

    const confirmedDialog = () => {
        dialogsActions.confirmedDialog(currentDialog._id);
    }

    const closeDialog = () => {
        setShowChild(true);
    }

    const transferDialog = (id) => {
        setTransferClass("d-block");
    }

    const handleTransfer = () => {
        dialogsActions.transferDialog(currentDialog._id, activeCategoryID);
    }

    const changeTransfer = (e) => {
        setCategoryID(e.target.value);
    }

    const createDialog = (item) => {
        let author = item.user.id;
        let text = item.text;
        let cat_id = item.dialog.cat_id;
        dialogsActions.createDialog(author, cat_id, text);
    }

    const updateMessage = (item) => {
        let id = item.id;
        let text = item.text;
        handleShow(id, text);
    }

    const deleteMessage = (item) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Сиз ростдан ҳам ушбу хабарни ўчирмоқчимисиз?")) {
            messagesApi
                .removeById(item._id)
                .then(() => {
                    window.location.reload();
                    // messagesActions.fetchMessages(currentDialog._id);
                })
                .catch(() => {
                    console.log("Ошибка при удалении сообщения");
                })
        }
    }

    let questionPartner = "";

    if (currentDialog.partner == null) {
        questionPartner = (
            <div className="question-partner">
                <h3>Чатни қабул қиласизми?</h3>

                <div className="confirmed-list">
                    <button className={"btn btn-primary"} onClick={() => confirmedDialog()}>Ҳа</button>
                    <Link to={"/"} className={"btn btn-danger"}>Йўқ</Link>
                </div>
            </div>
        )
    }

    return (
        <main className="main main-visible">

            <UpdateModal
                modelClose={() => handleClose()}
                modelShow={show}
                messageID={messageID}
                messageText={messageText}
            />

            <ChildModal
                modelClose={() => handleChildClose()}
                modelShow={showChild}
                dialogID={currentDialog._id}
                dialogCategory={currentDialog.cat_id._id}
            />

            <div className="chats">
                <div className="chat-body">
                    <div className="chat-header">
                        <Link to={"/"}
                              className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none mr-2"
                              type="button">
                            <svg className="hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
                            </svg>
                        </Link>

                        <div className="media chat-name align-items-center text-truncate">
                            <Avatar user={partner}/>

                            <div className="media-body align-self-center ml-3">
                                <h6 className="text-truncate mb-0">{partner.last_name}</h6>
                                <small className="text-muted">{partner.phone}</small>
                            </div>
                        </div>
                        <ul className="nav flex-nowrap">
                            <li className="nav-item list-inline-item d-none d-sm-block mr-1">
                                <div role="button" className="nav-link text-muted px-1" onClick={closeDialog}
                                     title="Чатни ёпиш">
                                    <svg className="hw-20 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"/>
                                    </svg>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="chat-content p-2" id="messageBody" ref={blockRef}>
                        <div className="container">
                            <div className="message-day">
                                {items.map(item => <MessageItem key={Math.random()} item={item} user={user}
                                                                currentDialog={currentDialog}
                                                                attachments={item.attachments}
                                                                createDialog={createDialog}
                                                                updateMessage={updateMessage}
                                                                deleteMessage={deleteMessage}/>)}
                            </div>
                            {questionPartner}
                        </div>
                        {
                            currentDialog.is_closed ?
                                <div className="chat-finished" id="chat-finished">Диалог ёпилган</div> : ""
                        }
                    </div>
                    <div className="chat-footer">
                        <ChatInput/>
                    </div>
                </div>
                <div className="chat-info chat-info-visible">
                    <div className="d-flex h-100 flex-column">
                        <div className="chat-info-header px-2">
                            <div className="container-fluid">
                                <ul className="nav justify-content-between align-items-center">
                                    <li className="text-center">
                                        <h5 className="text-truncate mb-0">Қўшимча маълумот</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="hide-scrollbar flex-fill">

                            <div className="text-center p-3">
                                <Avatar user={partner} type="lg"/>
                                <h5 className="mb-1 mt-3">{partner.last_name}</h5>
                            </div>
                            <div className="chat-info-group">
                                <div className="chat-info-group-body collapse show mt-2" id="profile-info">
                                    <div className="chat-info-group-content list-item-has-padding">
                                        <ul className="list-group list-group-flush ">
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Бўлим</p>
                                                <p className="mb-0">{currentDialog.cat_id.name_uz}</p>
                                                <p className="text-danger text-pointer"
                                                   onClick={() => transferDialog(currentDialog.id)}>Саволни бошқа
                                                    бўлимга ўтказиш</p>
                                                <div className={transferClass}>
                                                    <p className="small text-muted mb-0">Бўлимни танланг</p>
                                                    <select className="form-control mb-2 mt-2"
                                                            onChange={e => changeTransfer(e)}>
                                                        {
                                                            categories.filter(e => e.parent_id === "").map(e => <option
                                                                value={e._id} key={e._id}>{e.name_uz}</option>)
                                                        }
                                                    </select>
                                                    <button className="btn btn-sm btn-primary"
                                                            onClick={() => handleTransfer()}>Ўзгартириш
                                                    </button>
                                                </div>
                                            </li>
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Фамилия ва исми</p>
                                                <p className="mb-0">{partner.last_name}</p>
                                            </li>
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Телефон</p>
                                                <p className="mb-0">{partner.phone}</p>
                                            </li>
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Ёши</p>
                                                <p className="mb-0">{partner.age}</p>
                                            </li>
                                            <li className="list-group-item border-0">
                                                <p className="small text-muted mb-0">Жинси</p>
                                                <p className="mb-0">{partner.sex === "male" ? "Эркак" : ""}{partner.sex === "female" ? "Аёл" : ""}</p>
                                            </li>
                                            {/*<li className="list-group-item border-0">*/}
                                            {/*    <p className="small text-muted mb-0">Ногиронлиги мавжудлиги</p>*/}
                                            {/*    <p className="mb-0">{partner.age}</p>*/}
                                            {/*</li>*/}
                                            {/*<li className="list-group-item border-0">*/}
                                            {/*    <p className="small text-muted mb-0">Бандлиги</p>*/}
                                            {/*    <p className="mb-0">{partner.age}</p>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default connect(
    ({dialogs, messages, user, attachments}) => ({
        currentDialog: messages.items.dialog,
        items: messages.items.messages,
        isLoading: messages.isLoading,
        attachments: attachments.items,
        user: user.data,
        categories: dialogs.categories
    }),
    dialogsActions,
)(BaseMessages);

