import {axios} from "../../core";

export default {
    getAll: ({page, archive}) => axios.get("/dialogs", {params: {page: page, archive: archive}}),
    getArchives: () => axios.get("/dialogs", {params: {archive: true}}),
    search: (search) => axios.get("/search", {params: {"query": search}}),
    create: ({partner, text}) => axios.post("/dialogs", {partner, text}),
    confirmed: ({dialog_id}) => axios.put(`/user/dialogs/approve/${dialog_id}`),
    close: ({dialog_id, category_id, child_id}) => axios.put(`/user/dialogs/close/${dialog_id}`, {category_id: category_id, child_id: child_id}),
    transfer: ({dialog_id, cat_id}) => axios.put(`/operator/dialogs/transfer/${dialog_id}`, {cat_id: cat_id}),
    createDialog: ({cat_id, author, text}) => axios.post(`/operator/dialogs/create`, {cat_id: cat_id, author: author, text: text}),
    setCategory: ({dialog_id, cat_id}) => axios.put(`/user/dialogs/child/${dialog_id}`, {cat_id: cat_id}),
    getSelector: () => axios.get("selector")
};
