import React from 'react';
import Avatar from "./Avatar";
import {connect} from "react-redux";
import {userActions} from "../redux/actions";

const Welcome = ({user}) => {

    if (user == null) {
        return "";
    }

    return (
        <main className="main">
            <div className="chats">
                <div className="d-flex flex-column justify-content-center text-center h-100 w-100">
                    <div className="container">
                        <Avatar user={user} type="lg"/>
                        <h5 className={"mt-2"}>Ҳуш келибсиз, {user.last_name} {user.first_name} {user.middle_name}!</h5>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default connect(
    ({user}) => ({
        user: user.data,
    }),
    userActions,
)(Welcome);
