import {dialogsApi} from '../../utils/api';
import socket from "../../core/socket";

const Actions = {
    setDialogs: items => ({
        type: 'DIALOGS:SET_ITEMS',
        payload: items,
    }),
    setCategories: categories => ({
        type: 'CATEGORIES:SET_ITEMS',
        payload: categories,
    }),
    setCurrentPage: page =>({
        type: 'DIALOGS:CURRENT_PAGE',
        payload: page
    }),
    setIsArchive: archive =>({
        type: 'DIALOGS:IS_ARCHIVE',
        payload: archive
    }),
    updateReadedStatus: ({userId, dialogId}) => ({
        type: 'DIALOGS:LAST_MESSAGE_READED_STATUS',
        payload: {
            userId,
            dialogId,
        },
    }),
    setCurrentDialogId: id => dispatch => {
        socket.emit('DIALOGS:JOIN', id);
        dispatch({
            type: 'DIALOGS:SET_CURRENT_DIALOG_ID',
            payload: id,
        });
    },
    fetchDialogs: ({currentPage, isArchive}) => (dispatch) => {
        console.log("fetchDialogs", currentPage, isArchive);
        dialogsApi.getAll({page: currentPage, archive: isArchive}).then(({data}) => {
            dispatch(Actions.setDialogs(data.dialogs));
            dispatch(Actions.setCurrentPage(parseInt(data.currentPage)))
        });
    },
    fetchSearch: (search) => dispatch => {
        console.log("fetchSearch", search);
        dialogsApi.search(search).then(({data}) => {
            dispatch(Actions.setDialogs(data.dialogs));
        });
    },
    confirmedDialog: (dialog_id) => {
        dialogsApi.confirmed({dialog_id: dialog_id}).then(() => {
            window.location = "/dialog/" + dialog_id
        });
    },
    closeDialog: (dialog_id, category_id, child_id) => {
        socket.emit('DIALOGS:CLOSE', dialog_id);
        dialogsApi.close({dialog_id: dialog_id, category_id: category_id, child_id: child_id}).then(() => {
            window.location = "/dialog/" + dialog_id
        });
    },
    setChildCategory: (dialog_id, cat_id) => {
        dialogsApi.setCategory({dialog_id: dialog_id, cat_id: cat_id}).then(({data}) => {
            window.location = "/dialog/" + dialog_id
        });
    },
    createDialog: (author, cat_id, text) => {
        dialogsApi.createDialog({author: author, cat_id: cat_id, text: text}).then(({data}) => {
            window.location = "/dialog/"+ data._id;
        });
    },
    transferDialog: (dialog_id, cat_id) => {
        dialogsApi.transfer({dialog_id: dialog_id, cat_id: cat_id}).then(({data}) => {
            window.location = "/"
        });
    },
    fetchCategories: () => dispatch => {
        let isLocal = localStorage.getItem("selector");

        if(isLocal){
            let selector = JSON.parse(localStorage.getItem("selector"));
            dispatch(Actions.setCategories(selector.items));
        }else {
            dialogsApi.getSelector().then(({data}) => {
                dispatch(Actions.setCategories(data.category));
                localStorage.setItem("selector", JSON.stringify({
                    date: new Date(),
                    items: data.category
                }))
            });
        }
    },
};

export default Actions;
